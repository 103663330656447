import { Layout } from "antd";

const BlankLayout = ({ children }) => {
    return (
        <Layout>
            <Layout.Content>{children}</Layout.Content>
        </Layout>
    );
};

export default BlankLayout;
