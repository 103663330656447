import Script from "next/script";

import Analytics from "analytics";
import { AnalyticsProvider } from "use-analytics";
import * as Sentry from "@sentry/nextjs";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

import { useRouter } from "next/router";
import Head from "next/head";

import { ApolloProvider, gql } from "@apollo/client";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "lib/firebase/client";
import { FirebaseAuthProvider } from "lib/firebase/hooks";
import { cache, createClient, persistor } from "lib/apollo/client";
import FlagProvider from "lib/unleash";
import BlankLayout from "layouts/BlankLayout";

import "styles/global.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-datepicker/dist/react-datepicker.css";
import "components/ReactDatePicker/react-datepicker.css";

// Source: https://web.dev/customize-install/#detect-launch-type
const getPWADisplayMode = () => {
    if (document.referrer.startsWith("android-app://")) {
        return "twa";
    }

    if (
        navigator.standalone ||
        window.matchMedia("(display-mode: standalone)").matches
    ) {
        return "standalone";
    }

    return "browser";
};

const analyticsPlugins = [];

const analytics = Analytics({
    app: "enjin-konsol",
    version: process.env.NEXT_PUBLIC_ENJIN_VERSION || "unknown",
    plugins: analyticsPlugins,
});

analytics.ready(() => {
    if (typeof window !== "undefined") {
        analytics.page({
            displayMode: getPWADisplayMode(),
        });
    }
});

onAuthStateChanged(auth, (user) => {
    if (user) {
        Sentry.setUser({ id: user.uid, email: user.email });
        analytics.identify(user.uid, {
            email: user.email,
        });
    } else {
        Sentry.configureScope((scope) => scope.setUser(null));
        analytics.reset();
    }
});

cache.writeQuery({
    query: gql`
        query getCartItems {
            cartItems @client {
                uid
                tenantId
            }
        }
    `,
    data: {
        cartItems: [],
    },
});

const client = createClient(cache);

const EnjinKonsol = ({ Component, pageProps, err }) => {
    const router = useRouter();
    const Layout = Component.Layout || BlankLayout;

    useEffect(() => {
        const displayMode = getPWADisplayMode();

        const handleStart = () => {
            NProgress.start();
        };

        const handleComplete = (_, { shallow }) => {
            NProgress.done();
            if (!shallow) {
                analytics.page({
                    displayMode,
                });
            }
        };

        const handleStop = () => {
            NProgress.done();
        };

        router.events.on("routeChangeStart", handleStart);
        router.events.on("routeChangeComplete", handleComplete);
        router.events.on("routeChangeError", handleStop);

        return () => {
            router.events.off("routeChangeStart", handleStart);
            router.events.off("routeChangeComplete", handleComplete);
            router.events.off("routeChangeError", handleStop);
        };
    }, [router.events]);

    useEffect(() => {
        const init = async () => {
            try {
                persistor && persistor.restore();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        };

        if (typeof window !== "undefined") {
            // eslint-disable-next-line no-console
            init().catch(console.error);
        }
    }, []);

    return (
        <FirebaseAuthProvider>
            <Head>
                <meta
                    key="enjin-version"
                    property="enjin-version"
                    content={`enjin-konsol/${
                        process.env.NEXT_PUBLIC_ENJIN_VERSION || "unknown"
                    }`}
                />
                {process.env.DISABLE_INDEXING ? (
                    <meta name="robots" content="noindex, nofollow" />
                ) : null}
            </Head>

            <Script
                src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCc4s9kPaYWLBqMraYdMHc_ZAqv8zveF8c&libraries=places"
                onLoad={() => {
                    // eslint-disable-next-line no-undef
                    typeof initMap === "function" && initMap();
                }}
            />
            <Script
                src="https://static.cloudflareinsights.com/beacon.min.js"
                data-cf-beacon='{"token": "c329a1da4464478caf12e270e41c70b0"}'
            />

            <AnalyticsProvider instance={analytics}>
                <ApolloProvider client={client}>
                    <FlagProvider>
                        <Layout>
                            {/* `err` is a workaround for https://github.com/vercel/next.js/issues/8592 */}
                            <Component {...pageProps} err={err} />
                        </Layout>
                    </FlagProvider>
                </ApolloProvider>
            </AnalyticsProvider>
        </FirebaseAuthProvider>
    );
};

export default EnjinKonsol;
